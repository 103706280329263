.service-box {
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    box-shadow: 0px 24px 32px -3px rgba(3, 9, 50, 0.04);
    border-radius: 24px;
    padding: 32px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    min-height: 329px;
}

.service-box__link {
    background: var(--primary);
    border-radius: 40px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 20px 32px;
    display: flex;
    gap: 16px;
    height: 56px;
    justify-content: center;
    align-items: center;
}

.service-box__header {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    color: #1D2939;
}

.service-box__icon {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    color: #1D2939;
    background: #855fce2e;
}