.book-now-container {
    margin-top: 69px;
}

.book-now-container a {
    text-decoration: underline;
    color: black;
    font-size: medium;
}

.view-more {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 16px;
}


.book-now-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ads {
    margin-top: 32px;
}

.agent-details {
    display: flex;
    margin-bottom: 16px;
}

.agent-details span {
    font-weight: 500;
}