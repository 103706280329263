.login-wrapper {
    margin-top: 32px
}

.inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input {
    padding: 10px 14px;
    font-size: 16px;
    color: var(--primary-grey);
    border-radius: 8px;
}

.input:focus {
    background: #F7F8FF;
    border: 1px solid var(--primary-grey);
    ;
}

.login-input__wrapper label {
    margin-bottom: 6px;
}