.feature-box {
    padding: 10px 15px;
    border-radius: 15px;
    background: linear-gradient(180.1deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 99.91%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.feature-box__header {
    display: flex;
    justify-content: flex-end;
    column-gap: 5px;
    flex-wrap: wrap;
    row-gap: 5px;
}

.feature-box__pill {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
}

.feature-box__pill::before {
    content: ' ';
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 5px;
}

.location::before {
    background: #1C3F94;
}

.duration::before {
    background: #FF7373;
}

.apartment-type::before {
    background: #6AE284;
}

.feature-box__footer {
    color: #ffffff;
}

@media (max-width: 468px) {
    .feature-box.first-box {
        height: 215px !important;
    }
}