.site-layout {
    min-height: 80vh;
    padding: 0 50px;
}

.ant-menu-horizontal {
    border-bottom: 0;
}

.site-content {
    padding: 0px 30px;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after {
    border-bottom-width: 0;
    border-bottom-color: transparent;
}

@media screen and (max-width: 992px) {
    .site-layout {
        padding: 0 15px;
    }

    .site-content {
        padding: 0px 8px;
    }
}

@media screen and (max-width: 1017px) {
    .site-layout {
        padding: 0 15px;
    }
}

.hero-subtitle {
    max-width: 720px;
    text-align: center;
    margin: auto;
}