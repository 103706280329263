.image-grid-container {
    display: flex;
    justify-content: center;
}

.image-wrapper {
    max-width: 900px;
}

.ant-image-preview-wrap {
    background-color: rgb(55, 54, 60) !important;
}

.ant-image-preview-body {
    background-color: rgba(60, 55, 55, 0.443) !important;
}

.grid-item {
    height: 200px;
    overflow: hidden;
}

.ant-image-img {
    object-fit: cover;
    object-position: center !important;
    width: 100%;
    height: 100%;
}