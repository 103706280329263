.container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.form {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
}