.signin-container {
  padding-bottom: 60px;
  padding-top: 90px;
  text-align: center;
  width: 100%;
}

.signin-container .signup {
  width: 360px;
  margin: auto;
  text-align: start;
  margin-top: 50px;
}

.signup .signup-details {
  margin-top: 12px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-grey);
}

.signup .signup-text {
  font-size: 1.5rem;
  font-weight: 600;
}

.signin-container .signup .oauth-button svg {
  margin-right: 12px;
}

.oauth-button {
  width: 100%;
}

.signin-container .signup .login {
  margin-top: 33px;
  text-align: center;
}

.signin-container .signup .login a {
  text-decoration: none;
  color: var(--primary);
}

.signup .text {
  margin-top: 12px;
}

.signup .signupform .check {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.signup a {
  font-weight: 600;
}

.signup .signupform .check input {
  margin-right: 8px;
  cursor: pointer;
}

.signup .signupform .check label {
  color: var(--primary-grey);
  cursor: pointer;
}

.signup-input__wrapper {
  margin-top: 0px;
}

.signup-input__wrapper input {
  padding: 10px 14px;
  font-size: 16px;
  color: var(--primary-grey);
  border-radius: 8px;
}

.signup-input__wrapper input:focus {
  background: #f7f8ff;
  border: 1px solid var(--primary-grey);
}

.ant-select-selector {
  height: 47.14px !important;
  width: 100% !important;
  align-items: center;
  background: #f7f8ff;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-select-selector select:focus {
  background: #f7f8ff;
  border: 1px solid var(--primary-grey) !important;
}

.signup-input__wrapper span {
  font-size: 14px;
  color: var(--primary-grey);
}

.signin-container label {
  margin-bottom: 100px;
}

.form-group {
  display: flex;
  gap: 8px;
}
