.review__activities_wrapper .star {
    color: #FFAF02;
}

.num-of-reviews {
    margin-left: 10px;
}

.user__reviews__header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user__reviews__header .user__reviews__profile {
    display: flex;
    align-self: self-start;
}

.user__reviews__header .star {
    color: #FFAF02;
}

.user__reviews__header p {
    margin-top: 27px;
}