.search-form {
    width: 100%;
    padding-top: 16px;
}

.search-box {
    border-radius: 16px;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    box-shadow: 0px 24px 32px -3px rgba(3, 9, 50, 0.04);
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
}

.search-box>.search-box__item {
    flex: auto;
}

.search-form .ant-btn {
    font-weight: 400;
    font-size: 16px;
    padding: 0 15px;
    line-height: 0px;
}

.search-home-btn {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.search-home-btn button {
    width: 50%;
}

.filter-buttons {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: auto;
    margin-bottom: auto;

    .ant-row {
        column-gap: 10px;
        justify-content: flex-end;
    }
}

.search-box .ant-form-item .ant-form-item-label>label {
    font-size: 16px;
    font-weight: 600;
}

.search-box .ant-input {
    border-radius: 6px;
}

.search-box .ant-input {
    font-weight: 600;
    color: #8c8c8c;
}

.search-box .ant-input {
    border: 2px solid #aaaaaa;
    width: 100% !important;
}

.search-box .ant-select .ant-select-arrow {
    color: #aaaaaa;
}

.ant-select-selector {
    border: 2px solid #aaaaaa !important;
    width: 100% !important;
}

input {
    width: 100%;
}

.range-separator {
    justify-content: center;
    align-items: center;
    display: flex;
}

.range-separator::after {
    content: '-';
}

@media screen and (min-width: 992px) {
    form>.ant-row {
        justify-content: center;
    }
}

@media screen and (max-width: 991px) {
    .search-box__item.responsive {
        display: none;
    }

    .search__text {
        display: none;
    }

    .range-separator {
        display: none;
    }
}