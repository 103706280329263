.main {
    display: flex;
    margin-bottom: 30px;
}

.main .cards .pagination {
    display: flex;
    justify-content: center;
    margin: auto;
}

.apartment-wrapper {
    width: 100%;
    margin: auto;
}

@media (max-width: 1000px) {
    .main {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
}

.featured-boxes {
    padding: 0 0px 30px 0px;
}