.how-it-works__image {
    background: linear-gradient(41.64deg, rgba(64, 133, 247, 0.27) 25.39%, rgba(64, 133, 247, 0) 131.52%), linear-gradient(0deg, #C4C4C4, #C4C4C4), #C4C4C4;
    box-shadow: 0px 22px 32px rgba(63, 135, 247, 0.16);
    border-radius: 32px;
    width: 523px;
    height: 405px;
}

.how-it-works__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* margin: auto auto; */
}

.link-button {
    max-width: 200px;
}