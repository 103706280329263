.login-container {
    padding-bottom: 60px;
    padding-top: 90px;
    text-align: center;
}

.login {
    width: 360px;
    text-align: start;
    margin: 50px auto;
}

.signup {
    margin-top: 33px;
    text-align: center;

}

.login .signup a {
    text-decoration: none;
    color: var(--primary);
}

.login .login__welcome-back {
    margin-top: 12px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-grey);
}

.login .login-text {
    font-size: 1.5rem;
    font-weight: 600;
}

.signup a {
    font-weight: 600;
}