.NewsLetterContainer {
    text-align: center;
    height: 382px;
    padding: 27px;
    border: 1px solid #d0d5dd;
    border-radius: 16px;
    margin-left: auto;
}

.NewsLetterContainer p {
    margin: 0px;
}

.NewsLetterContainer input {
    height: 56px;
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;
}

.NewsLetterContainer button {
    height: 60px;
    margin-top: 16px;
    border-radius: 8px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
}

@media (max-width:980px) {
    .NewsLetterContainer {
        display: none;
    }
}