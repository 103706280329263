body {
  margin: 0;
  font-family: 'Fellix';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.page-content {
  max-width: 1440px;
  margin: auto;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-message {
  position: absolute;
  bottom: 20px !important;
  top: unset !important;
}

.active {
  color: var(--primary) !important;
}

h1,
h2,
h3 {
  font-family: 'Jost';
  font-style: normal;
  color: var(--primary);
}

h1 {
  font-weight: 700;
  font-size: 52px;
  line-height: 75px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--primary);
}

h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: -0.03em;
}

h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.03em;
}

.text-primary {
  color: var(--primary);
}

.text-primary-dark {
  color: var(--primary-dark);
}

.text-primary-grey {
  color: var(--primary-grey);
}

.text-white {
  color: #fff;
}

.bg-primary {
  background-color: var(--primary);
}

.bg-primary-dark {
  background-color: var(--primary-dark);
}

.bg-primary-grey {
  background-color: var(--primary-grey);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-wrap {
  white-space: wrap;
}

.text-semibold {
  font-weight: 500;
}

.text-mediumbold {
  font-weight: 600;
}

.text-mediumbold {
  font-weight: 700;
}

.text-smallest {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-big {
  font-size: 24px;
}

.text-medium {
  font-size: 18px;
}

.text-subdued {
  color: var(--primary-grey);
}

.text-blue {
  color: var(--primary);
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.link-button {
  border-radius: 40px;
  text-decoration: none;
  padding: 20px 32px;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  height: 56px;
  justify-content: center;
  align-items: center;
}

.title-section {
  margin-bottom: 40px;
  width: 100%;
}

.entry-button {
  border: none;
  font-weight: 600;
  font-size: 16px;
  color: white;
  height: 44px;
  width: 100%;
  background: var(--primary);
  box-shadow: 0px 8px 26.85px rgba(47, 46, 65, 0.35);
  border-radius: 8px;

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: no-drop;
  }
}

.relative {
  position: relative;
}

.nav-search {
  position: sticky;
  top: 0;
  z-index: 100;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: end;
}

.gap {
  gap: 10px
}