.entry-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.first-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    padding-top: 200px;
    text-align: center;
    background: linear-gradient(43.41deg, #eff4ff 0%, #eff4ff 69.16%);
}

.first-container .details {
    margin: 50px auto;
    width: 400px;
}

.first-container .details p {
    text-align: center;
    margin: 0px;
}

.first-container .details .details-text__first {
    font-size: 1.5rem;
    color: var(--primary)
}

.first-container .details .details-text__second {
    font-size: 0.75rem;
    color: var(--primary-grey)
}


@media (max-width: 1024px) {
    .first-container {
        display: none;
    }

    .entry-wrapper {
        display: flex;
        justify-content: center;
    }

}