.ant-layout-header {
    height: 97px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #FFFFFF;
    border-bottom: 1px solid #E9EBF0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navigation,
.auth {
    display: flex;
    font-weight: 700;
    opacity: 0.8;
    font-size: 20px;
    column-gap: 10px;
}

.mobile-menu {
    font-weight: 700;
    opacity: 0.8;
    font-size: 20px;
}

.mobile-menu-button {
    display: none;
}

.header-content {
    display: flex;
    flex: auto;
    max-width: 1440px;
    margin: auto;
    align-items: center;
}

@media (max-width: 1017px) {
    .main-menu {
        display: none;
    }

    .header-content {
        justify-content: space-between;
    }

    .mobile-menu-button {
        display: block;
    }
}

.navigation {
    flex: 2 1;
    justify-content: center;
}

.main-menu a {
    color: #333333;
}

.login-button {
    padding: 10px 25px;
    background: var(--primary);
    color: #FFF !important;
    border-radius: 5px;
}

.ant-menu-horizontal>.ant-menu-item a:hover {
    color: #22408f;
}

li.ant-menu-item.ant-menu-item-only-child {
    width: inherit;
}

.header-content {
    display: flex;
    flex: auto;
    max-width: 1440px;
    margin: auto;
    justify-content: space-between;
}