.comfirmCodeContainer {
    padding-bottom: 60px;
    padding-top: 90px;
    text-align: center;
    width: 100%;
}

.comfirmCodeContainer img {
    margin-top: 45px;
}

.comfirmCodeContainer .signup {
    width: 360px;
    margin: auto;
    margin-top: 50px;
}

.comfirmCodeContainer .signup>span:last-child {
    margin-top: 8px;
}


.comfirmCodeContainer .signup .title {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    color: var(--primary);
}


.comfirmCodeContainer .signup .six-digit {
    text-align: center;
    color: var(--primary-grey);
}

.comfirmCodeContainer .signup .resend {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.comfirmCodeContainer .signup .resend>p {
    margin-left: 10px;
    margin-top: 9px;
}

.comfirmCodeContainer .signup span {
    color: var(--primary);
    font-weight: 500;
}

.comfirmCodeContainer .signup .pincode-input-container {
    margin: 40px 0px;

}

.comfirmCodeContainer .signup .pincode-input-container .pincode-input-text {
    padding: 0 !important;
    margin: 0 2px;
    text-align: center;
    background: #f7f8ff;
    border: 1px solid #c0c0c0;
    border-radius: 16px;
    width: 64px;
    height: 64px;
}

.comfirmCodeContainer .signup .pincode-input-container .pincode-input-text:focus {
    border: 1.5px solid #1c3f94;
    outline: none;
    box-shadow: none;
}