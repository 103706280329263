.or {
  display: flex;
  flex-direction: row;
  margin-bottom: 32.5px;
}

.or:before,
.or:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #898888;
  margin: auto;
}

.or span {
  margin-left: 1rem;
  margin-right: 1rem;
}

.oauth-button p {
  font-size: 1rem;
  margin: auto 0px;
}

.oauth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  border: 1px solid var(--primary-grey);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-bottom: 12px;
}

.oauth-button svg {
  margin-right: 12px;
}
