.offers__details-container {
    margin-top: 16px;
}

.offers-details {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 8px;
}

.offers-details_item {
    display: flex;
    gap: 8px;
    align-items: center;
}