.main-image {
    border-radius: 16px 0px 0px 16px;
    height: 500px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: inline-block;
}

.map-image {
    width: 100%;
}

.sub-image {
    border-radius: 0px 16px 16px 0px;
    height: 248px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: inline-block;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.main-image:hover .image-overlay {
    opacity: 1;
}

.sub-image:hover .image-overlay {
    opacity: 1;
}

.property-image {
    object-fit: cover;
    object-position: center;
    display: block;
    width: 100%;
    height: 100%;
}

.show-all-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: white;
    color: black;
    z-index: 5;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.header-text {
    display: flex;
    justify-content: space-between;
    margin: 24px 0px;
}

.header-text .action {
    display: flex;
    gap: 2px;
}

.sub-button {
    box-shadow: none !important;
}


@media (max-width: 1000px) {
    .header-text .action {
        display: none;
    }

    .main-image {
        border-radius: 0px;
    }

    .sub-image {
        border-radius: 0px;
    }
}

.header-text .action div {
    display: flex;
    gap: 2px;
}

.details__page .property__serviced {
    display: flex;
    justify-content: space-between;
    margin-top: 69px;
    margin-bottom: 16px;
}

.details__page .property__serviced h4 {
    color: #3F4354;
    font-size: 22px;
    padding: 0px;
    margin: 0px;
}

.details__page .property__serviced .badge {
    width: 102px;
    height: 34px;
    padding: 8px 16px;
    border-radius: 20px;
    background: #F0FFFA;
    color: #0DA06A;
}

.details__page .property__serviced .badge__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.offers__details {
    display: flex;
    gap: 18px;
}

.property-search__location {
    margin-top: 27px;
}