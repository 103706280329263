@import "~antd/dist/reset.css";
@import "~@fontsource/jost"; /* Defaults to weight 400 */
@import "~@fontsource/jost/400.css"; /* Specify weight */
@import "~@fontsource/jost/400-italic.css"; /* Specify weight and style */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #1C3F94;
  --primary-dark: #031432;
  --primary-grey:#667085;
}

@media (max-width: 1017px) {
  .home-screen-padding {
      padding: 0 15px;
  }
}

@media (min-width: 1017px) {
  .home-screen-padding {
      padding: 0 50px;
  }
}
